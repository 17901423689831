<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="강의장 예약하기">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="bbs.showReservation" class="btn-default">예약 현황</button>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <button @click="bbs.showCalendar" class="day btn-cal">
                            {{ bbs.ym }}
                            <v-datepicker v-model="bbs.tdate" @dayclick="bbs.doSelect" class="datepicker-group">
                                <template #default="{ inputValue, togglePopover }">
                                    <div>
                                        <input type="text" :value="inputValue" style="display:none;" />
                                        <button class="btn-weeks" @click="togglePopover()"></button>
                                    </div>
                                </template>
                            </v-datepicker>
                        </button>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>
                    
                    <div class="block-title mt-50 mb-20">강의장 사용현황(시간)</div>
                    <table class="table-col">
                        <colgroup>
                            <col width="200">
                            <col span="7">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>층</th>
                                <th v-for="d in bbs.dayList" :key="d">{{ d }}</th>
                                <th>합계/평균</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.cname }}</td>
                                <td>{{ irow.day[1] }}</td>
                                <td>{{ irow.day[2] }}</td>
                                <td>{{ irow.day[3] }}</td>
                                <td>{{ irow.day[4] }}</td>
                                <td>{{ irow.day[5] }}</td>
                                <td>{{ irow.day[6] }}</td>
                                <td>{{ irow.day[0] }}</td>
                                <td>{{ irow.total }}</td>
                            </tr>
                            <tr class="total">
                                <td>합계</td>
                                <td>{{ bbs.total[1] }}</td>
                                <td>{{ bbs.total[2] }}</td>
                                <td>{{ bbs.total[3] }}</td>
                                <td>{{ bbs.total[4] }}</td>
                                <td>{{ bbs.total[5] }}</td>
                                <td>{{ bbs.total[6] }}</td>
                                <td>{{ bbs.total[0] }}</td>
                                <td>{{ bbs.total[0] + bbs.total[1] + bbs.total[2] + bbs.total[3] + bbs.total[4] + bbs.total[5] + bbs.total[6] }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="dp-table w-100per mt-50">
                        <div class="float-left w-32per">
                            <div class="dp-table w-100per mb-20">
                                <div class="float-left block-title">본부별 사용현황</div>
                                <div class="float-right bold">총 {{bbs.office.total}}시간</div>
                            </div>
                            <table class="table-col">
                                <colgroup>
                                    <col width="*">
                                    <col width="100">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>본부명</th>
                                        <th>시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="irow in bbs.office.list" :key="irow.idx">
                                        <td>{{irow.deptname}}</td>
                                        <td>{{irow.hours}}</td>
                                    </tr>
                                    <tr v-if="bbs.office.list.length==0">
                                        <td colspan="2">사용내역이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="float-left w-36per pl-25 pr-25">
                            <div class="dp-table w-100per mb-20">
                                <div class="float-left block-title">팀별 사용현황</div>
                                <div class="float-right bold">총 {{bbs.team.total}}시간</div>
                            </div>
                            <table class="table-col">
                                <colgroup>
                                    <col width="*">
                                    <col width="100">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>팀명</th>
                                        <th>시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="irow in bbs.team.list" :key="irow.idx">
                                        <td>{{irow.deptname}}</td>
                                        <td>{{irow.hours}}</td>
                                    </tr>
                                    <tr v-if="bbs.team.list.length==0">
                                        <td colspan="2">사용내역이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="float-left w-32per">
                            <div class="dp-table w-100per mb-20">
                                <div class="float-left block-title">고객사별 사용현황</div>
                                <div class="float-right bold">총 {{bbs.customer.total}}시간</div>
                            </div>
                            <table class="table-col">
                                <colgroup>
                                    <col width="*">
                                    <col width="100">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>고객사명</th>
                                        <th>시간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="irow in bbs.customer.list" :key="irow.idx">
                                        <td>{{irow.kname}}</td>
                                        <td>{{irow.hours}}</td>
                                    </tr>
                                    <tr v-if="bbs.customer.list.length==0">
                                        <td colspan="2">사용내역이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { DatePicker } from 'v-calendar'


export default {
    layout: 'ResourceManagement',
    components: {
        'v-datepicker' : DatePicker,
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        
        const bbs = reactive({
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],
            ym     : 0,
            tdate  : [],

            list : [], total : 0,

            office   : { list:[] },
            team     : { list:[] },
            customer : { list:[] },

            doSelect : () => {
                let nd = new Date(bbs.tdate);
                bbs.ym = [ nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1) ].join('-');

                bbs.doSearch();
            },

            doPrev : () => {
                let td = new Date(bbs.tdate);
                bbs.tdate = new Date(td.getFullYear(), td.getMonth()-1, 1);
                bbs.doSelect();
            },

            doNext : () => {
                let td = new Date(bbs.tdate);
                bbs.tdate = new Date(td.getFullYear(), td.getMonth()+1, 1);
                bbs.doSelect();
            },

            showReservation : () => {
                router.push({
                    name : 'ResourceManagement-ClassroomReservationDate'
                })
            },

            doSearch : () => {
                let params = {
                    tdate : bbs.ym
                };
                axios.get('/rest/resourceManagement/getClassroomReservationStatisticsReport', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.classroom;
                        bbs.total = res.data.sum_days;

                        bbs.office   = res.data.office;
                        bbs.team     = res.data.team;
                        bbs.customer = res.data.company;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-01';
            bbs.tdate = today;
            bbs.ym = bbs.tdate.substr(0, 7);

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
.btn-weeks {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; opacity: 0.001; z-index: 1;
}
</style>